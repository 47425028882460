<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefanía Villada Chavarría                                   ###### -->
<!-- ###### @date: Septiembre 2024                                                 ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <div class="d-flex">
      <!-- Filtro por código bodega -->
      <v-text-field v-model="buscar" class="campo" label="Buscar por: Afiliado, aseguradora, prestador o médico" dense outlined
        color="primary">
      </v-text-field>

      <!-- <div class="d-flex campo">
        <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
          :close-on-content-click="false" :return-value.sync="filtro.fechaInicio" :nudge-top="25" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="me-2 campo" v-model="filtro.fechaInicio" label="Fecha inicial" v-bind="attrs" v-on="on"  
              outlined dense readonly color="primary">
            </v-text-field>
          </template>
          <v-date-picker v-model="filtro.fechaInicio" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cleanFechaInicial">Borrar</v-btn>
            <v-btn text color="primary" @click="saveFechaInicial">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
          :close-on-content-click="false" :return-value.sync="filtro.fechaFin" :nudge-top="25" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field class="me-2 campo" v-model="filtro.fechaFin" label="Fecha final" v-bind="attrs" v-on="on" 
              :error-messages="rangoFechas|| instruccionFechas" outlined dense readonly color="primary">
            </v-text-field>
          </template>
          <v-date-picker v-model="filtro.fechaFin" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cleanFechaFinal">Borrar</v-btn>
            <v-btn text color="primary" @click="saveFechaFinal">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab color="primary" v-bind="attrs" v-on="on" :disabled="!reporte">
              <v-icon> picture_as_pdf </v-icon>
            </v-btn>
          </template>
            <span>Generar reporte</span>
        </v-tooltip>
      </div> -->
    </div>

    <!-- Tabla de las notificaciones -->
    <v-data-table class="elevation" :items="tablaNotificaciones.items" fixed-header hover
      :headers="tablaNotificaciones.headers" :loading="tablaNotificaciones.loading"
      :page.sync="tablaNotificaciones.page" :server-items-length="tablaNotificaciones.totalElements"
      :footer-props="tablaNotificaciones.footerProps" no-data-text="No se encontraron resultados"
      :items-per-page.sync="tablaNotificaciones.itemsPerPage" height="64vh">
      <template v-slot:[`item.afiliado`]="{ item }">
        <span>{{ item.afiliado.numeroDocumento }} - {{ item.afiliado.nombreCompleto }}</span>
      </template>
      <template v-slot:[`item.aseguradora`]="{ item }">
        <span>{{ item.aseguradora.idAseguradora }} - {{ item.aseguradora.nombreAseguradora }}</span>
      </template>
      <template v-slot:[`item.medico`]="{ item }">
        <span>{{ item.medico.nombres }} {{ item.medico.apellidos }}</span>
      </template>
      <template v-slot:[`item.descripcion`]="{ item }">
        <v-icon medium color="blue" @click="abrirDialogoDetalles(item)">library_add</v-icon>
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        <span>{{ formatoFecha(item.createDate) }}</span>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para ver los detalles de un contrato -->
    <v-dialog v-model="dialogDetalles" transition="dialog-bottom-transition" max-width="35rem">
      <v-card>
        <v-card-title class="encabezadoDialogo"> Descripción </v-card-title>
        <v-card-text class="black--text pt-5 detalles">
          <span v-if="notificacion !== ''"> {{ notificacion }} </span>
          <span v-else> No se encontró la descripción de la notificación.</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import moment from 'moment';

export default {
  name: 'Notificaciones',
  data() {
    return {
      buscar: '',
      filtro: {
        fechaInicio: '',
        fechaFin: '',
      },
      menuFechaInicio: false,
      menuFechaFinal: false,
      instruccionFechas: '',
      rangoFechas: '',
      tablaNotificaciones: {
        loading: false,
        items: [],
        headers: [
          { text: "AFILIADO", value: 'afiliado', width: "20%", sortable: false },
          { text: "SEGURADORA", value: 'aseguradora', width: "20%", sortable: false },
          { text: "PRESTADOR", value: 'prestador.nombrePrestador', width: "20%", sortable: false },
          { text: "MÉDICO", value: 'medico', width: "20%", sortable: false },
          { text: "DESCRIPCIÓN", value: 'descripcion', width: "10%", sortable: false, align: 'center' },
          { text: "FECHA NOTIFICACIÓN", value: 'fecha', width: "10%", sortable: false, align: 'center'},
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalElements: 0
      },
      dialogDetalles: false,
      notificacion: '',
    }
  },
  mounted() {
    this.listarNotificaciones();
  },
  computed: {
    ...mapState(["enterprise"]),
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
    reporte() {
      return this.filtro.fechaInicio && this.filtro.fechaFin && this.rangoFechas === '' && this.instruccionFechas === '' && !this.menuFechaInicio && !this.menuFechaFinal;
    }
  },
  watch: {
    'tablaNotificaciones.page' :function () {
      this.listarNotificaciones();
    },
    'tablaNotificaciones.itemsPerPage' :function () {
      this.tablaNotificaciones.page = 1;
      this.listarNotificaciones();
    },
    'buscar': function () {
      this.tablaNotificaciones.page = 1;
      this.listarNotificaciones();
    }
  },
  methods: {
    listarNotificaciones() {
      this.tablaNotificaciones.loading = true;
      this.$http.get(`/msa-setting/api/notificacionPafi/list`, {
        params: {
          afiliado: this.buscar,
          aseguradora: this.buscar,
          prestador: this.buscar,
          medico: this.buscar,
          page: this.tablaNotificaciones.page - 1,
          size: this.tablaNotificaciones.itemsPerPage,
        }
      }).then((response) => {
        this.tablaNotificaciones.items = response.data.body.content;
        this.tablaNotificaciones.totalElements = response.data.body.totalElements;
        this.tablaNotificaciones.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tablaNotificaciones.loading = false;
      })
    },
    /**
     * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
     * se asegura de que el rango de fechas seleccionadas no exceda 6 meses. Si alguna de estas condiciones no se cumple, 
     * establece un mensaje de error correspondiente.
     */
      validacionesFechas() {
        this.instruccionFechas = '';
        this.rangoFechas = '';
        const fechaUno = new Date(`${this.filtro.fechaInicio}T00:00:00-05:00`);
        const fechaDos = new Date(`${this.filtro.fechaFin}T00:00:00-05:00`);

        const rangoUnMes = new Date(fechaUno);
        rangoUnMes.setMonth(fechaUno.getMonth() + 5);
        
        if (fechaUno > fechaDos) {
          this.instruccionFechas = 'La fecha final debe ser mayor a la fecha inicial';
          return;
        } 
        
        let fechaMaximaPermitida = new Date(fechaUno);
        fechaMaximaPermitida.setMonth(fechaMaximaPermitida.getMonth() + 5);
        
        if (fechaUno.getDate() === 1) {
          fechaMaximaPermitida.setDate(0);
        }

        if (fechaDos > fechaMaximaPermitida) {
          this.rangoFechas = 'Las fechas seleccionadas no deben exceder 6 meses';
        }
    },
    formatoFecha(date) {
      if (date === null) return;
      
      return moment(date).format('YYYY-MM-DD hh:mm:ss');
    },
    /** Guarda la fecha inicial seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaInicial() {
      this.$refs.menuFechaInicio.save(this.filtro.fechaInicio);
      this.validacionesFechas();
    },
    /** Limpia la fecha inicial seleccionada y restablece los campos relacionados. */
    cleanFechaInicial() {
      this.$refs.menuFechaInicio.save('');
      this.filtro.fechaInicio = '';
      this.instruccionFechas = '';
    },
    /** Guarda la fecha final seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaFinal() {
      this.$refs.menuFechaFinal.save(this.filtro.fechaFin);
      this.validacionesFechas();
    },
    /** Limpia la fecha final seleccionada y restablece los campos relacionados. */
    cleanFechaFinal() {
      this.$refs.menuFechaFinal.save('');
      this.filtro.fechaFin = '';
      this.instruccionFechas = '';
    },
    abrirDialogoDetalles(item) {
      this.notificacion = item.descripcion;
      this.dialogDetalles = true;
    }
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.campo {
  width: 50%;
}

.detalles {
  max-height: 40vh;
  overflow-y: auto;
}

.encabezadoDialogo{
    background-color: #1867c0;
    color: white;
}
</style>